// GLOBAL SCSS

// IMPORT LIBRARIES
@import "_lib/_normalize.scss"; // Normalize.css - CSS Reset

//IMPORT CONFIG
@import "_config/config"; // Initial values
@import "_config/functions"; // Initial functions
@import "_config/root"; // Initial root

// IMPORT PARTIALS
@import "_partials/_base"; // Initiate basic elements
@import "_partials/_fonts"; // Initiate fonts
@import "_partials/_margins"; // Initiate margins and padding

// IMPORT MODULES
// Add here the scss files for the website

@import "_modules/base";


::-moz-selection { /* Code for Firefox */
  color: red;
  background: yellow;
}

::selection {
  color: yellow;
  background: grey;
}

html, body{
  position: fixed;
  height: 100%;
  width: 100%;
  top:0;
  left:0;
  overflow: hidden;
}

body{
  font-size: 21px;
  line-height: 1.1em;
  background-color: grey;
}


a:hover, a.active{
  color: yellow;
}

.dash{
  line-height: 2em;
}

.project:hover{
  color: dimgrey;
}

body{
  background-color: lightgray;
}

main{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 999;
  position: fixed;
  width: auto;
  left: 50%;
  transform: translate(-50%, 0);
  top:0;
}

.welcome{
  padding: 0.5em 0.5em 1em;
  align-items: center;
  text-align: center;
  width: 100%;
  min-width: 420px;

}

.recent{
  text-align: center;
  line-height: 1.1em;
  z-index: 9999999999;
  .project{
    display: none;
  }
}

#about{
  position: fixed;
  bottom: 0.5em;
  z-index: 99;
  left:50%;
  transform: translateX(-50%);
}

.about{
  display: none;
  p:first-of-type{
    text-align: center;
  }
  text-align: center;
  max-width: 450px;
  p{
    margin-bottom: 1em;
  }
}

.project{
  .hidden-infos{
    display: none;
  }
}
.project:hover{
  .hidden-infos{
    display: block;
  }
}

/*.video{
  position: absolute;
  left: 0;
  top:0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container{
width: 40%;
}

.video-wrap{
  position: relative;
  padding-bottom: 56.25%; !* 16:9 *!
  padding-top: 25px;
  width: 100%; !* enlarge beyond browser width *!
  overflow: hidden;
  iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}*/


* { box-sizing: border-box; }

.video-container{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  &.hide{
    opacity: 0 !important;
  }
}

.video-wrapper{
  width: 449px;
  height: 253px;
  position: relative;
  pointer-events: none;
}
.video-background {
  background: #000;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 0;
  position: absolute;
  overflow: hidden;
}
.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#vidtop-content {
  top: 0;
  color: #fff;
}

.video-poster{
  display: none;
}


@media (min-aspect-ratio: 16/9) {
  .video-foreground { height: 300%; top: -100%; width: 101%; }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground { width: 300%; left: -100%; height: 101%; }
}
@media all and (max-width: 600px) {
  .vid-info { width: 50%; padding: .5rem; }
  .vid-info h1 { margin-bottom: .2rem; }
}
@media all and (max-width: 500px) {

}

@media (max-width:530px){

  main{
    width: 100%;
    display: block;
  }

  body{
    font-size: 18px;
  }

  .welcome{
    min-width: auto;
  }

  .video-wrapper{
    width: 80vw;
    height: 45vw;
    position: relative;
    pointer-events: none;
  }

  .about{
    padding: 0 1.25em;
    max-width: 100%;
  }

  .recent{
    padding: 0 1.25em;
    max-width: 100%;
  }

  .video-background{
    display: none;
  }

  .video-poster{
    display: block;
    height: 100%;
  }

}

@media (max-width:530px) and (hover: none){

  .project:hover{
    .hidden-infos{
      display: none;
    }
  }
}
